import React, { useState } from 'react';
import { useStore } from 'effector-react';
import {
    TableCell,
    TableRow,
    IconButton,
    AvatarGroup,
    Avatar,
    Select,
    MenuItem,
    Badge,
    Icon
} from '@mui/material';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { EmojiEvents, Check, Close } from '@mui/icons-material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import '../styles/month-report-row.scss';
import { setAchievementForUser, deleteUserAchievement } from './api';
import { $authUserData } from '../store/cards';
import { achievementConditions } from '../shared/enums';

export function MonthReportRow(props) {
    const {
        row,
        showTasks,
        showReviewTasks,
        showSupportTasks,
        showRulesTasks,
        manuallyAchievements,
        selectedSprint,
        updateMonthReport
    } = props;
    const [open, setOpen] = useState(false);
    const [showAchievementSelector, setShowAchievementSelector] = useState(false);
    const [selectedAchievement, setSelectedAchievement] = useState('');
    const authUserData = useStore($authUserData);

    const setAchievementClick = () => {
        const achievementData =
        {
            userId: row.id,
            achievementId: selectedAchievement,
            month: selectedSprint.split(';')[0],
            year: selectedSprint.split(';')[1]
        };
        setAchievementForUser(achievementData).then(_ => {
            setSelectedAchievement('');
            setShowAchievementSelector(false);
            updateMonthReport();
        });
    };

    const closeAchievementSelector = () => {
        setSelectedAchievement('');
        setShowAchievementSelector(false);
    };

    const reslutSPCount = ((showTasks ? row.tasks_sp_count : 0)
        + (showReviewTasks ? row.review_tasks_sp_count : 0)
        + (showSupportTasks ? row.support_tasks_sp_count : 0)
        + (showRulesTasks ? row.rules_tasks_sp_count : 0)).toFixed(2);

    const deleteAchievement = (id) => {
        const achievementData =
        {
            userId: row.id,
            achievementId: id,
            month: selectedSprint.split(';')[0],
            year: selectedSprint.split(';')[1]
        };
        deleteUserAchievement(achievementData).then(_ => {
            updateMonthReport();
        });
    }



    return (
        <React.Fragment>
            <TableRow key={row.id}>
                <TableCell>
                    <div className='month-report-row__functional-buttons'>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {row.tasks.filter(task => task.sp_count === 0).length > 0
                        ? <Icon className='month-report-row__hight-priorty-icon'>
                            <PriorityHighIcon/>
                        </Icon>
                        : ""}
                    </div>
                </TableCell>
                <TableCell align="left">
                    <div className='month-report-row__user-data'>
                        {
                            authUserData && authUserData.is_head_of_department
                                ? <IconButton onClick={() => setShowAchievementSelector(!showAchievementSelector)}>
                                    <EmojiEvents className="month-report-row__open-selection-achievement" />
                                </IconButton>
                                : null
                        }
                        {showAchievementSelector
                            ? <div>
                                <Select
                                    style={{ height: 25, width: 200 }}
                                    value={selectedAchievement}
                                    onChange={(event) => setSelectedAchievement(event.target.value)}
                                >
                                    {manuallyAchievements
                                        .filter(ma => row.achievements.filter(a => a.id === ma.id).length < 1)
                                        .map(achievement =>
                                            <MenuItem key={achievement.id} value={achievement.id}>
                                                {achievement.name}
                                            </MenuItem>
                                        )}
                                </Select>
                                <IconButton onClick={setAchievementClick}>
                                    <Check className="month-report-row__functional-button" />
                                </IconButton>
                                <IconButton onClick={closeAchievementSelector}>
                                    <Close className="month-report-row__functional-button" />
                                </IconButton>
                            </div>
                            : <p
                                style={{ color: row.text_color }}
                                title={row.conditions.map(condition => achievementConditions[condition]).join(', ')}
                            >
                                {`${row.surname} ${row.name}`}
                            </p>}
                        <AvatarGroup max={6} style={{marginLeft: 10}}>
                            {
                                row.achievements.map(achievement =>
                                    <div key={achievement.id}>
                                        <Badge
                                            overlap="circular"
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            badgeContent={authUserData && authUserData.is_head_of_department ?
                                                <IconButton onClick={() => deleteAchievement(achievement.id)}>
                                                    <Close className="month-report-row__functional-button" />
                                                </IconButton>
                                                : null
                                            }
                                        >
                                            <Avatar
                                                className='month-report-row__achievement-icon'
                                                title={achievement.name}
                                                alt={achievement.name}
                                                src={`data:image/jpeg;base64,${achievement.icon}`}
                                            />
                                        </Badge>
                                    </div>

                                )
                            }
                        </AvatarGroup>
                    </div>
                </TableCell>
                <TableCell align="right">
                    {
                        reslutSPCount > 0
                            ? <b>{reslutSPCount}</b>
                            : <p>{reslutSPCount}</p>
                    }
                </TableCell>
                <TableCell align="right">
                    <b>
                        {showTasks ? row.total_task_count : 0}
                    </b>
                </TableCell>
            </TableRow>
            {!open ? null : (

                row.tasks
                    .filter(t => {
                        const isNotTasks = showTasks ? true : t.id < 0;
                        const isNotReview = showReviewTasks ? true : t.id !== -1;
                        const isNotSupportTask = showSupportTasks ? true : t.id !== -2;
                        const isNotRulesTasks = showRulesTasks ? true : t.id !== -3;
                        return isNotTasks && isNotReview && isNotSupportTask && isNotRulesTasks;
                    })
                    .map(t => {
                        return <TableRow key={t.id} style={{ background: t.sp_count === 0 ? '#ff2b2b' : null }}>
                            <TableCell><a href={`https://bitrix.borauto.ru/company/personal/user/1835/tasks/task/view/${t.bitrix_task_id}/`} target="_blank" rel="noreferrer">{t.bitrix_task_id}</a></TableCell>
                            <TableCell align="left" className='month-report__row-title' title={t.title}>{t.title}</TableCell>
                            <TableCell align="right">{t.sp_count}</TableCell>
                            <TableCell align="right">{t.id > 0 ? "1" : ""}</TableCell>
                        </TableRow>
                    })
            )}
        </React.Fragment>
    );

}